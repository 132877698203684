import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageSquareCode: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z' }],
    ['path', { d: 'm10 8-2 2 2 2' }],
    ['path', { d: 'm14 8 2 2-2 2' }],
  ],
];

export default MessageSquareCode;
