import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageSquareDot: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11.7 3H5a2 2 0 0 0-2 2v16l4-4h12a2 2 0 0 0 2-2v-2.7' }],
    ['circle', { cx: '18', cy: '6', r: '3' }],
  ],
];

export default MessageSquareDot;
