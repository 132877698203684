import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageSquareShare: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 12v3a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h7' }],
    ['path', { d: 'M16 3h5v5' }],
    ['path', { d: 'm16 8 5-5' }],
  ],
];

export default MessageSquareShare;
