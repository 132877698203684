import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageCirclePlus: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7.9 20A9 9 0 1 0 4 16.1L2 22Z' }],
    ['path', { d: 'M8 12h8' }],
    ['path', { d: 'M12 8v8' }],
  ],
];

export default MessageCirclePlus;
