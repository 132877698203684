import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Speaker: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '16', height: '20', x: '4', y: '2', rx: '2' }],
    ['path', { d: 'M12 6h.01' }],
    ['circle', { cx: '12', cy: '14', r: '4' }],
    ['path', { d: 'M12 14h.01' }],
  ],
];

export default Speaker;
