import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Guitar: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm20 7 1.7-1.7a1 1 0 0 0 0-1.4l-1.6-1.6a1 1 0 0 0-1.4 0L17 4v3Z' }],
    ['path', { d: 'm17 7-5.1 5.1' }],
    ['circle', { cx: '11.5', cy: '12.5', r: '.5' }],
    [
      'path',
      {
        d: 'M6 12a2 2 0 0 0 1.8-1.2l.4-.9C8.7 8.8 9.8 8 11 8c2.8 0 5 2.2 5 5 0 1.2-.8 2.3-1.9 2.8l-.9.4A2 2 0 0 0 12 18a4 4 0 0 1-4 4c-3.3 0-6-2.7-6-6a4 4 0 0 1 4-4',
      },
    ],
    ['path', { d: 'm6 16 2 2' }],
  ],
];

export default Guitar;
