import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageCircleMore: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7.9 20A9 9 0 1 0 4 16.1L2 22Z' }],
    ['path', { d: 'M8 12h.01' }],
    ['path', { d: 'M12 12h.01' }],
    ['path', { d: 'M16 12h.01' }],
  ],
];

export default MessageCircleMore;
