import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FileMusic: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '14', cy: '16', r: '2' }],
    ['circle', { cx: '6', cy: '18', r: '2' }],
    ['path', { d: 'M4 12.4V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2h-7.5' }],
    ['path', { d: 'M8 18v-7.7L16 9v7' }],
  ],
];

export default FileMusic;
