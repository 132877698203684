import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageSquareX: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z' }],
    ['path', { d: 'm14.5 7.5-5 5' }],
    ['path', { d: 'm9.5 7.5 5 5' }],
  ],
];

export default MessageSquareX;
