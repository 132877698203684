import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const InspectionPanel: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '18', height: '18', x: '3', y: '3', rx: '2' }],
    ['path', { d: 'M7 7h.01' }],
    ['path', { d: 'M17 7h.01' }],
    ['path', { d: 'M7 17h.01' }],
    ['path', { d: 'M17 17h.01' }],
  ],
];

export default InspectionPanel;
