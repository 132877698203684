import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageCircleCode: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7.9 20A9 9 0 1 0 4 16.1L2 22Z' }],
    ['path', { d: 'm10 10-2 2 2 2' }],
    ['path', { d: 'm14 10 2 2-2 2' }],
  ],
];

export default MessageCircleCode;
