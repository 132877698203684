import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageCircle: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M7.9 20A9 9 0 1 0 4 16.1L2 22Z' }]],
];

export default MessageCircle;
