import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MessageCircleWarning: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7.9 20A9 9 0 1 0 4 16.1L2 22Z' }],
    ['path', { d: 'M12 8v4' }],
    ['path', { d: 'M12 16h.01' }],
  ],
];

export default MessageCircleWarning;
