import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Anvil: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 10c-2.8 0-5-2.2-5-5h5' }],
    ['path', { d: 'M7 4v8h7a8 8 0 0 0 8-8Z' }],
    ['path', { d: 'M9 12v5' }],
    ['path', { d: 'M15 12v5' }],
    ['path', { d: 'M5 20a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v1H5Z' }],
  ],
];

export default Anvil;
