import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const UserSearch: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '10', cy: '7', r: '4' }],
    ['path', { d: 'M10.3 15H7a4 4 0 0 0-4 4v2' }],
    ['circle', { cx: '17', cy: '17', r: '3' }],
    ['path', { d: 'm21 21-1.9-1.9' }],
  ],
];

export default UserSearch;
