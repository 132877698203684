import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Squircle: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9-9 9-9-1.8-9-9 1.8-9 9-9' }]],
];

export default Squircle;
